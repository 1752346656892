import { writable, derived, readable } from "svelte/store";
import { items, updateItems } from '@parkingboss/svelte-utils'
import { filter, merge, get, pick, map, has, each, find, every, unset, orderBy, debounce, pickBy } from "lodash-es";
import { format, startOfDay, endOfDay, addHours, startOfHour } from "date-fns";
import { api } from "./api";
import { client as mqtt } from "./mqtt";
import { params, param } from "./params";
import { utcToZonedTime } from "date-fns-tz";

export {
    params
}

const comparer =  !!window.Intl && !!window.Intl.Collator ? new Intl.Collator(undefined, { numeric: true, sensitivity: 'base', caseFirst: 'lower' }).compare : (a, b) => a < b ? -1 : a > b ? 1 : 0;

export const minuteNow = readable(new Date(), set => {
  set(new Date());
  const i = setInterval(() => set(new Date()), 60 * 1000);
  return () => clearInterval(i);
});
export const secondNow = readable(new Date(), set => {
  set(new Date());
  const i = setInterval(() => set(new Date()), 1000);
  return () => clearInterval(i);
});

export const timeStore = function(seconds) {
    return readable(new Date(), set => {
        const i = setInterval(() => {
          set(new Date());
        }, seconds * 1000);
          return () => clearInterval(i);
      });
};


//export const state = writable({});

export const state = items;

export const view = param("view");
export const propertyId = param("property", true);
export const policyId = param("policy");


// background update watcher
export const updated = writable({});
let subscribedPropertyId = null;
const mqttConnection = mqtt("AKIAIUPPRVWKBYHY4UWQ", "GQQeZRDLfbR9JpVeIuAJzcAOgSlaJXABCRsqR3M8", json => {
    //console.log("mqtt.message=", json);
    //updated.set(json);
    updated.set({
        scope:subscribedPropertyId,
        type: Object.keys(json)[0],
        updated: json[Object.keys(json)[0]],
    });
});
propertyId.subscribe(propertyId => {
    if(!propertyId) return;
    if(subscribedPropertyId == propertyId) return;

    // propertyId changed...

    // unsubscribe
    if(!!subscribedPropertyId) mqttConnection.unsubscribe(`locations/${subscribedPropertyId}`);

    // subscribe
    mqttConnection.subscribe(`locations/${subscribedPropertyId = propertyId}`);
});

updated.subscribe(value => {
    if(!value || !value.scope) return;
    fetchAndStorePropertyPoliciesStatistics(value.scope);
});


const fetchAndStorePropertyPoliciesStatistics = debounce(async function(property) {
    const json = await Promise.all([
        fetch(`${api.settings.apiBase}/permits/policies/issue/statistics?scope=${property}&viewpoint=${new Date().toISOString()}&valid=${format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss")}/&public=true&actual=false`),
    ])
    .then(values => Promise.all(values.map(res => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then(values => merge({}, ...values));

    each(json, (value, key) => {
        //console.log(key, value);
        if(has(value, "items")) json.items[key] = value.items;
    });

    updateItems(json);
}, 1000, {
    trailing:true,
    leading:true,
});

async function fetchAndStorePropertyPolicies(id) {
    const json = await Promise.all([
        fetch(`${api.settings.apiBase}/properties?viewpoint=${new Date().toISOString()}&property=${id}`),
        fetch(`${api.settings.apiBase}/permits/policies/issue?scope=${id}&viewpoint=${new Date().toISOString()}&valid=${format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss")}/&public=true&actual=false&slots=true`),
        //fetch(`${api.settings.apiBase}/units?scope=${id}&viewpoint=${new Date().toISOString()}`),
        //fetch(`${api.settings.apiBase}/spaces?scope=${id}&viewpoint=${new Date().toISOString()}`)
    ])
    .then(values => Promise.all(values.map(res => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then(values => merge({}, ...values));

    each(json, (value, key) => {
        //console.log(key, value);
        if(has(value, "items")) json.items[key] = value.items;
    });

    updateItems(json);

    //state.update(prev => merge(prev, json.items));
}

// once you're on a property, assumed until the actual value changes
// store previous value and use value equality to prevent frothy requests
let propertyRefresher = null;
let previousPropertyId = null;
propertyId.subscribe(async value => {
    //if(!!propertyRefresher) clearInterval(propertyRefresher); // alway stop the scheduler

    if(!value) return; // don't do anything, but keep previous value cached

    if(value === previousPropertyId) return; // the assignment changed, but not the actual value;

    // changing value, reset...
    state.update(state => {
        unset(state, "policies");
        return state;
    });

    if(!!propertyRefresher) clearInterval(propertyRefresher); // stop the previous scheduler

    previousPropertyId = value;

    //console.log("propertyId changed=", value);

    // permit id changed
    await fetchAndStorePropertyPolicies(value);
    fetchAndStorePropertyPoliciesStatistics(value)
    propertyRefresher = setInterval(() => fetchAndStorePropertyPoliciesStatistics(value), 5 * 60 * 1000);

   
});

function resolveAddress(item, items) {
    if(!item) return item;
    item.address = items[item.address] || item.address;
    return item;
}

function resolveProperty(item, items) {
    if(!item) return item;
    if(typeof item === "string") item = items[item];
    return resolveAddress(item, items);
}


export const property = derived([ propertyId, state ], ([ id, items ]) => resolveProperty(items[id], items));

export const policies = derived([ property, state ], ([ property, state ]) => {
    if(!property) return null;
    if(!state["policies"]) return null;
    var policies = map(state["policies"], (version, policy) => state[policy] || state[version]);
    //console.log("policies=", policies);
    if(!policies.every(item => !!item)) return null; // not done loading

    return policies
    .filter(item => !!item && item.scope === property.id && item.amenity !== "parking").map(item => {
        item.statistics = get(state, [ "statistics", item.id ]) || get(state, [ "statistics", item.subject ]);
        //item.meters.items = map(item.meters.items, m => state[m] || m);
        item.property = resolveProperty(item.location, state);
        return item;
    }).sort((a, b) => comparer(a.title, b.title));
});

export const policy = derived([ policyId, policies ], ([ id, policies ]) => {
    var item = !!id && !!policies && policies.find(item => item.id === id || item.subject === id);
    return item;
});

export const propertyNow = derived([property, minuteNow], ([property, now]) => {
    //console.log("propertyNow=", property,  property.timezone, now);
    return utcToZonedTime(now, property && property.timezone);
});

//state.subscribe($value => console.log("state=", $value));
propertyId.subscribe($value => console.log("property id=", $value));
property.subscribe($value => console.log("property=", $value));

policies.subscribe($value => console.log("policies=", $value));
policyId.subscribe($value => console.log("policy id=", $value));
policy.subscribe($value => console.log("policy=", $value));