<script>
    import { onMount } from 'svelte';
    import { toMilliseconds } from "duration-fns";  
    import { minuteNow } from "../util/stores";
    import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
    import { stringToInterval, stringToDates, deviceTimeAsIfLocal, iso8601, iso8601Offset, display, minDate, maxDate, toInterval } from "../util/datetime";
    import { addHours, parseISO, startOfHour, startOfDay, endOfDay, isWithinInterval, addDays, addMilliseconds, isSameDay, roundToNearestMinutes, isBefore, isAfter, areIntervalsOverlapping, getYear } from "date-fns";
    import { get, map, reduce, first, last, filter, uniqBy, pickBy, orderBy } from "lodash-es";
    import Usage from "./Usage.svelte";
    import Time from "./Time.svelte";
    import page from "page";


    export let policy = null;
    export let selectable = false;
    export let minDuration = "PT0S";
    export let maxDuration = minDuration;
    export let available = false;
    export let capacity = false;
    export let used = false;
    export let selected = null;
    export let date = null;
    export let maxIntervals;
    

    let element = null;
    let datesOpen = false;
    
    $: timezone = policy && policy.timezone;

    $: statistics = get(policy, "statistics.intervals");
    $: console.log("statistics=", policy.statistics);

    $: chooseDates = timezone && uniqBy(map(statistics, (v, k) => ({ start: startOfDay(utcToZonedTime(new Date(k.split('/')[0]), timezone)), end: endOfDay(utcToZonedTime(new Date(k.split('/')[0]), timezone)) })), i => format(i.start, "yyyy-MM-dd", { timeZone: timezone }));
    
    $: dateInterval = date ? { start: startOfDay(utcToZonedTime(date, timezone)), end: endOfDay(utcToZonedTime(date, timezone)) } : chooseDates && chooseDates.length > 3 && chooseDates[0];

    $: if(date) console.log("date=", utcToZonedTime(date, timezone));
    $: console.log("choosedates = ", chooseDates);
    $: console.log("dateinterval=", dateInterval);

    $: minDuration = get(policy, "duration.min") || get(policy, "duration");
    $: maxDuration = get(policy, "duration.max") || get(policy, "duration");
    $: minTimeMS = toMilliseconds(minDuration);
    $: maxTimeMS = toMilliseconds(maxDuration);
    $: multiple = maxTimeMS > minTimeMS;

    //$:console.log("min=", minDuration, minTimeMS, "max=", maxDuration, maxTimeMS);


    // make sure we recalculate when stats changes
    $: selectedInterval = toInterval(selected);

    $: agenda = buildAgenda($minuteNow, dateInterval ? pickBy(statistics, (value, interval) => {
        const test = toInterval(interval);
        //console.log("test", test, dateInterval);
        // const utc = {
        //     start:  zonedTimeToUtc(dateInterval.start, timezone),
        //     end:    zonedTimeToUtc(dateInterval.end, timezone),
        // };
        // console.log("test", test, utc);
        return test && areIntervalsOverlapping(test, {
            start:  zonedTimeToUtc(dateInterval.start, timezone),
            end:    zonedTimeToUtc(dateInterval.end, timezone),
        });
    }) : statistics, selected, selectable, multiple, maxTimeMS, maxIntervals);

    //$: minUtc = agenda && agenda[0] && agenda[0].utc[0];
    //$: maxUtc = agenda && agenda[0] && agenda[agenda.length - 1].utc[1];
    //$: minLocal = agenda[0].local[0];
    //$: maxLocal = agenda[agenda.length - 1].local[1];
    $: minOriginal = agenda && agenda[0] && agenda[0].original[0];
    $: maxOriginal = agenda && agenda[0] && agenda[agenda.length - 1].original[1];
    //$: interval = [ agenda[0].utc[0], agenda[agenda.length - 1].utc[1] ].map(d => d.toISOString()).join("/");

    // $: console.log(timesWithResult);
    //$: console.log("selected=", selectedInterval);
    $: hasSelected = !!selectedInterval && selectedInterval.end - selectedInterval.start > 0;


    function select(interval) {
        if(!interval) return null;
        var parsed = stringToInterval(interval);
        if(parsed.end - parsed.start <= 0) return null;
        return interval;
    }
    
    function maySelectTime(asOf, selected, slotToConsider, slots, multiple, maxTimeMS) {
        //console.log(asOf, selected, slotToConsider);
        //if(!selectable) return false; // global selectable switch

        const selectedInterval = stringToInterval(selected);

        if(!!slotToConsider && slotToConsider.end < asOf) return false;
        if(!selected) return true;
        if(!multiple) return true; // selected, but can't do multiple
        if(selectedInterval.end - selectedInterval.start <= 0) return true;
        //console.log("diff=", selected.end - selected.start, maxTimeAllowed);
        if(!slotToConsider) return (selected.end - selected.start) < maxTimeMS;

        // check entire period
        const max = {
            start:Math.min(selectedInterval.start, slotToConsider.start),
            end:Math.max(selectedInterval.end, slotToConsider.end),
        };

        //console.log("maySelectTime=", selected, slotToConsider, max);

        if((max.end - max.start) > maxTimeMS) return false;

        // this is slow
        if(slots.some(item => item.start >= max.start && item.end <= max.end && item.block)) return false; // we found a slot inside of max that is full or closed

        return true;
    }

    function buildAgenda(asOf, usage, selected, selectable, multiple, maxTimeMS, maxIntervals) {
        
        const times = orderBy(Object.entries(usage || {}), [ "0" ]).reduce((result, [ interval, value ]) => {

            let [ usage, title ] = value.split("=");

            usage = usage.split("/").map(i => parseInt(i));
            // convert to client relative time
            const local = stringToDates(interval, deviceTimeAsIfLocal);
            const utc = stringToDates(interval);
            //.split("/").map(d => new Date(d.replace(/[+-][0-9]{1,2}:?[0-9]{2}$/, format(new Date(d), "xxx")))).map(d => format(d, "yyyy-MM-dd'T'HH:mm:ssxxx")).join("/");
            //const utcInterval = interval.split("/").map(d => new Date(d).toISOString()).join("/");
            //console.log("usage=", usage, interval, localInterval, usage);
            var prev = result[result.length - 1];


            const item = {
                prev: (prev && prev.original[0]),
                original:interval.split("/"),
                interval: interval,
                title,
                utc: utc,
                local: local,
                used:usage[0],
                capacity:usage[1],
                available: (usage[1] - usage[0]),
                full: (usage[0] >= usage[1]),
                closed: (usage[1] === 0),
                block: (usage[0] >= usage[1] || usage[1] === 0),
                past:(utc[1] < asOf),
                start:utc[0],
                end:utc[1],
            };
            //result[utc.map(d => d.toISOString()).join()] = item;
            if(utc[1] >= asOf) result.push(item);

            return result;
        }, []);

        return orderBy(times.map(t => {

            const currentlySelected = isSelected(selected, t);

            //console.log('status=', currentlySelected, selected, t);

            return Object.assign(t, {
                selected:currentlySelected,
                selectable:currentlySelected || (!!selectable && !t.block && maySelectTime(asOf, selected, t, times, multiple, maxTimeMS)),
            });
        }), [ "start"]).slice(0, maxIntervals);
    }

    function addSelected(selected, interval) {
        if(!selected) return select(interval);
        if(isSelected(selected, interval)) return select(selected); // can't add if already selected

        var intervalParts = interval.split('/');
        var selectedParts = selected && selected.split('/');

        interval = stringToInterval(interval);
        selected = stringToInterval(selected);

        if(interval.start < selected.start && interval.end < selected.end) return select(`${intervalParts[0]}/${selectedParts[1]}`);
        if(interval.start < selected.start && interval.end > selected.end) return select(`${intervalParts[0]}/${intervalParts[1]}`);
        if(interval.start > selected.start && interval.end > selected.end) return select(`${selectedParts[0]}/${intervalParts[1]}`);

    }

    function isSelected(selected, interval) {
        if(!selected || !interval) return false;
        if(typeof(selected) === "string") selected = stringToInterval(selected);
        if(typeof(interval) === "string") interval = stringToInterval(interval);
        return interval.start >= selected.start && interval.end <= selected.end; // selected must fully encompass interval
    }

    function removeSelected(selected, interval) {
        //console.log("remove=", interval, selected);
        if(!isSelected(selected, interval)) return select(selected); // can't remove if not already selected

        var intervalParts = interval.split('/');
        var selectedParts = selected && selected.split('/');

        interval = stringToInterval(interval);
        selected = stringToInterval(selected);

        //console.log("removeSelected=", selected, interval);

        if(interval.start <= selected.start) return select(`${intervalParts[1]}/${selectedParts[1]}`); // at the beginning
        if(interval.end >= selected.end) return select(`${selectedParts[0]}/${intervalParts[0]}`); // at the end
        return select(`${selectedParts[0]}/${intervalParts[0]}`); // in the middle
    }

    function onChange(e) {
        

        var interval = e.target.value;

        //console.log("onchange=", e.target.checked, e.target.value, interval, selected);
        if(e.target.type === "radio") interval = select(interval);
        else if(isSelected(selected, interval)) interval = removeSelected(selected, interval);
        else if(!isSelected(selected, interval)) interval = addSelected(selected, interval);

        // navigate
        //return page(`${location.pathname}?selected=${interval||""}`);

        var qs = new URLSearchParams(location.search);
        if(!interval) qs.delete("selected");
        else qs.set("selected", interval);
        return page(`${location.pathname}?${qs.toString()}`);
        

    }
    
    

</script>

<figure class="agenda" bind:this={element}>
    {#if false && dateInterval && chooseDates && chooseDates.length > 1}
        <ul class="dates" on:click={e => datesOpen = !datesOpen} class:open={datesOpen}>
            {#each chooseDates as item}
                <li class:selected={areIntervalsOverlapping(item, dateInterval)}>
                    <a href="?date={format(new Date(item.start.getFullYear(), item.start.getMonth(), item.start.getDate(), 12, 0, 0), "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: timezone })}">
                        <time datetime="{format(item.start, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: timezone })}/{format(item.end, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: timezone })}">{format(item.start, "EEEE MMMM d", { timeZone: timezone })}</time>
                    </a>
                </li>
            {/each}
        </ul>
    {/if}
    <time class="range" datetime="{minOriginal}/{maxOriginal}" on:change={onChange}>
        <!-- <Time value={$minuteNow.toISOString()} relativeTo="{minOriginal}/{maxOriginal}" /> -->
        {#if hasSelected} 
            {#each agenda.filter(item => item.end <= selectedInterval.start) as item}
                <Time value={item.original[0]} previous={item.prev} />
                <time datetime="{item.interval}" class:past={maxDate(item.interval) < $minuteNow} class:future={minDate(item.interval) > $minuteNow} class:closed={item.closed}>
                    <Usage title={item.title} available={available && !item.closed && item.available} capacity={(capacity || item.closed) && item.capacity} used={used && item.used} />
                    {#if item.selectable}
                        <input type="{multiple ? "checkbox" : "radio"}" name="valid" value="{item.interval}" />
                        <span></span>
                    {/if}
                </time>
            {/each}
            {#if agenda.filter(item => item.start >= selectedInterval.start && item.end <= selectedInterval.end).length == 1}
                {#each agenda.filter(item => item.start >= selectedInterval.start && item.end <= selectedInterval.end) as item}
                    <Time value={item.original[0]} previous={item.prev} />
                    <time datetime="{item.interval}" class="selected" class:past={maxDate(item.interval) < $minuteNow} class:future={minDate(item.interval) > $minuteNow} class:closed={item.closed}>
                        <Usage title={item.title} available={available && !item.closed && item.available} capacity={(capacity || item.closed) && item.capacity} used={used && item.used} />
                        {#if item.selectable}
                            <input type="{multiple ? "checkbox" : "radio"}" name="valid" value="{item.interval}" />
                            <span></span>
                        {/if}
                    </time>
                {/each}
            {:else}
                <time datetime="{selectedInterval.start.toISOString()}/{selectedInterval.end.toISOString()}" class="selected">
                    {#each agenda.filter(item => item.start >= selectedInterval.start && item.end <= selectedInterval.end) as item}
                        <Time value={item.original[0]} previous={item.prev} />
                        <time datetime="{item.interval}" class:past={maxDate(item.interval) < $minuteNow} class:future={minDate(item.interval) > $minuteNow} class:closed={item.closed}>
                            <Usage title={item.title} available={available && !item.closed && item.available} capacity={(capacity || item.closed) && item.capacity} used={used && item.used} />
                            {#if item.selectable}
                                <input type="{multiple ? "checkbox" : "radio"}" name="valid" value="{item.interval}" />
                                <span></span>
                            {/if}
                        </time>
                    {/each}
                </time>
             {/if}
            {#each agenda.filter(item => item.start >= selectedInterval.end) as item}
                <Time value={item.original[0]} previous={item.prev} />
                <time datetime="{item.interval}" class:past={maxDate(item.interval) < $minuteNow} class:future={minDate(item.interval) > $minuteNow} class:closed={item.closed}>
                    <Usage title={item.title} available={available && !item.closed && item.available} capacity={(capacity || item.closed) && item.capacity} used={used && item.used} />
                    {#if item.selectable}
                        <input type="{multiple ? "checkbox" : "radio"}" name="valid" value="{item.interval}" />
                        <span></span>
                    {/if}
                </time>
            {/each}
        {:else}
            {#each agenda as item}
                <Time value={item.original[0]} previous={item.prev}/>
                <time datetime="{item.interval}" class:past={maxDate(item.interval) < $minuteNow} class:future={minDate(item.interval) > $minuteNow} class:closed={item.closed}>
                    <Usage title={item.title} available={available && !item.closed && item.available} capacity={(capacity || item.closed) && item.capacity} used={used && item.used} />
                    {#if item.selectable}
                        <input type="{multiple ? "checkbox" : "radio"}" name="valid" value="{item.interval}" />
                        <span></span>
                    {/if}
                </time>
            {/each}
        {/if}
        <Time value={maxOriginal} previous={agenda && agenda.length && get(agenda, [ agenda.length - 1, "original", 0 ])}/>
    </time>
</figure>