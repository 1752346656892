<script>
    import Photo from "./Photo.svelte";
    import Agenda from "./Agenda.svelte";
    import QR from "./QR.svelte";
    export let policy;
</script>

<article class="policy">
<header>
    <Photo item={policy} />
    <h1>{policy.title}</h1>
    <!-- {#if policy.disabled}
    <aside class="alert info closed">
        <p>{@html policy.disabled.reason.replace(/\n|\r\n|\r/g, "<br>")}</p>
    </aside>
{:else}
    {#if policy.alert}
        <aside class="alert info">
            <p>{@html policy.alert.text.replace(/\n|\r\n|\r/g, "<br>")}</p>
        </aside>
    {/if}
    {#if policy.info}
        <aside class="info">
            <p>{@html policy.info.text.replace(/\n|\r\n|\r/g, "<br>")}</p>
        </aside>
    {/if}
{/if} -->
{#if policy.permit.enabled && policy.permit.required}
<data class="permit" value="required">Pass Required</data>
<p>An active pass is required for use</p>
{:else if policy.permit.enabled}
<data class="permit" value="priority">Pass Priority</data>
<p>Active passes have priority to use, otherwise open on a first-come, first-served basis. Please be considerate.</p>
{:else}
<data class="permit" value="">Open To Use</data>
<p>Open on a first-come, first-served basis. Please be considerate.</p>
{/if}

</header>




<QR policy={policy} />
<Agenda policy={policy} available={true} maxIntervals={8} />
    
<!-- <section class="info">
    
    <p>Please see Amenity Pass app for full info and rules or to get a pass</p>
    
</section>
<section class="schedule">
    <header>
        <h1>Availability</h1>
    </header>
    
</section> -->
</article>