<script>

    import { minuteNow } from "../util/stores";
    import { format, isSameDay, addDays } from "date-fns";
    import { stringToDates, deviceTimeAsIfLocal, display } from "../util/datetime";

    export let value = null;
    export let previous = null;
    export let relativeTo = null;
    let displayAsLocal = true; // this can fake the display side if in a different offset

    $: parts = !!value && value.split('/');
    $: globals = !!value && (value.toISOString ? [ value ] : stringToDates(value));
    $: locals = !!value && (value.toISOString ? [ value ] : stringToDates(value, deviceTimeAsIfLocal));
    $: prev = previous && stringToDates(previous, deviceTimeAsIfLocal)[0];

    //$: console.log($minuteNow, globals)

    $: isInterval = parts && parts.length == 2;

    $: percent = globals && globals[0] && !!relativeTo ? percentOfRange(globals[0], ...stringToDates(relativeTo)) : null;

    $: past = globals && globals[globals.length - 1] < $minuteNow; //isBefore(global, $minuteNow);
    $: future = globals && globals[0] > $minuteNow; //isBefore(global, $minuteNow);
    $: now = !past && !future;
    $: hour = locals && locals[0] && locals[0].getMinutes() == 0;
    $: day = hour && locals[0].getHours() == 0;

    function formatTimePoint(point, relativeTo) {
        if(!point) return "";

        console.log("formatTimePoint", point, relativeTo);

        if(point.getHours() % 12 == 0 && point.getMinutes() == 0) return format(point, "E h:mm a"); // always do noon/midnight

        if(!!relativeTo) {

            if(relativeTo.getDay() !== point.getDay()) {
                if(point.getMinutes() === 0) return format(point, "E h:mm a");
                return format(point, "E h:mm a");
            }

            if(relativeTo.getHours() !== point.getHours()) {
                if(point.getMinutes() === 0) return format(point, "h:mm a");
                return format(point, "h:mm a");
            }
            
            // same hour, show minutes
            return format(point, "h:mm a");

        }

        if(point.getMinutes() === 0) return format(point, "E h:mm a");
        return format(point, "E h:mm a");

        //return format(point, "E h:mm a");
        //return format(point, () ? "E h a" : point.getMinutes() == 0 ? "h a" : ":mm");
    }

    function percentOfRange(value, min, max) {
        if(!value || !min || !max) return null;
        return ((value - min) / (max - min)) * 100;
    }

</script>
<time datetime="{value || ""}" class:past={past} class:now={now} class:future={future} style="{!!percent ? `top:${percent}%;` : ""}" class:hourstart={hour} class:daystart={day}>
{#if isInterval}
    <time class="start min" datetime="{parts[0]}"><dfn>Start</dfn> {format(locals[0], locals[0] <= addDays(new Date(), 6) ? "E h:mm a" : "MMM d h:mm a")}</time>
    <time class="end max" datetime="{parts[1]}"><dfn>End</dfn> {format(locals[1], isSameDay(locals[0], locals[1]) ? "h:mm a" : locals[1] <= addDays(new Date(), 6) ? "E h:mm a" : "MMM d h:mm a")}</time>
{:else}
    {#if locals && locals[0]}
    <abbr title="{display(locals[0])}">{formatTimePoint(locals[0], prev)}</abbr>
    {/if}
    {#if locals && locals[1]}
    <abbr title="{display(locals[1])}">{formatTimePoint(locals[1])}</abbr>
    {/if}
{/if}
    
</time>