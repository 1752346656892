<script>
    export let property = null;
    export let policies = null;
    export let size = "1080p";

    import { format } from "date-fns";
    import { readable } from "svelte/store";

    import Logo from "./Logo.svelte";
    import Policy from "./Policy.svelte";
    import Version from "./Version.svelte";
    import Overview from "./Overview.svelte";
    import QR from "./QR.svelte";
    import { policy, propertyId, propertyNow } from "../util/stores";

    let pageSize = 3;
    let index = 0;

    console.log(policies);

    const toPages = (policies, pageSize) =>
        policies.reduce(
            (rows, key, index) =>
                (index % pageSize == 0
                    ? rows.push([key])
                    : rows[rows.length - 1].push(key)) && rows,
            []
        );

    let pages = toPages(policies, pageSize);

    function getRotateStore(items, milliseconds) {
        return readable(
            items[index], // default value
            function start(set) {
                // start is called on first subscribe to store, set to first item
                set(items[index]);
                // timer to interval across the items and reset on end
                let interval = setInterval(() => {
                    set(
                        items[
                            index < items.length - 1
                                ? (index += 1)
                                : (index = 0)
                        ]
                    );
                }, milliseconds);
                // clear interval when no more subscribers
                return function stop() {
                    clearInterval(interval);
                };
            }
        );
    }

    let store = getRotateStore(pages, 10000);
</script>

<main data-resolution={size} class:policy={!!$policy}>
    <header>
        <h1>{(property && property.name) || "Loading…"}</h1>
        
    </header>
    <figure class="status">
        <time datetime="{$propertyNow}">Live {format($propertyNow, "h:mm a")}</time>
        <Version />
    </figure>

    {#if $policy}
        <Policy policy={$policy} />
    <!-- {:else}
        <div class="quickview">
            {#each $store as amenity}
                <Overview item={amenity} />
            {/each}
            <div class="qr">
                <QR property={property} />
            </div>
        </div>
        <div class="pages">
            {#each pages as page}
                <div class="indicator {pages.indexOf(page) === index ? 'currentpage':''}"
                    on:click={() => {
                        index = pages.indexOf(page);
                        store = getRotateStore(pages, 10000);
                    }}
                ></div>
            {/each}
        </div> -->
    {/if}
    <footer/>
</main>
