<script>
    export let item = null;
</script>
{#if item && item.photo}
    <figure class="photo">
        <img class="photo" alt="Photo of {item.title || item.name}" src="{item.photo.url.replace("https://upload.parkingboss.com/files/", "https://amenitypass.imgix.net/")}?auto=compress,format"/>
    </figure>
    {:else}
    <figure class="photo">
        <div class="placeholder"></div>
    </figure>
{/if}