import App from "./App.svelte";
import { end, router } from "./util/router";

const app = new App({ target: document.body, props: {} });

// function view(viewname) {
//     return function(ctx, next) {
//         ctx.params.view = viewname;
//         next();
//     };
// }

//router("/properties/:property", view("property"), end);

// policy home
router("/properties/:property/amenities/:policy", end);

router("/", end);


router.start();