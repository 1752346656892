<script>
import Screen from './components/Screen.svelte';
import { property, policies } from './util/stores';

</script>
<header>
</header>
{#if $property && $policies}
    <Screen property={$property} policies={$policies} />
{:else}
<figure>Loading…</figure>
{/if}
<footer>
</footer>