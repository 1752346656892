import { Api } from '@parkingboss/api';
import { updateItems } from '@parkingboss/svelte-utils';
import { iso8601Offset } from "./datetime";
import { addDays, format, startOfDay } from "date-fns";
import { merge, get, pick, map, has, each, find, every, unset, orderBy, debounce } from "lodash-es";
import { writable } from "svelte/store";
import store from "store/dist/store.modern";

export const api = new Api({
    client: "parkingattendant",
});

//let authHeader = "";
//api.user.subscribe(auth => auth && (authHeader = `Authorization=${auth.type} ${auth.token}`));


export const auth = writable(store.get("auth"));
// write to backing store
auth.subscribe($auth => $auth && store.set("auth", $auth));

export async function responseJson(response) {
    if(!response) return {};
    return response.text()
    .then(function(text) {

        if(!text) return {
            status: response.status
        };

        return Promise.resolve(text)
        .then(JSON.parse)
        .catch(function(error) {
            return {
                status: response.status,
                message: text
            };
        });

    })
    .catch(function(error) {
        return {
            status: response.status
        };
    });
}


function resolveAddress(item, items) {
    if(!item) return item;
    item.address = items[item.address] || item.address;
    return item;
}

export function resolveProperty(item, items) {
    if(!item) return item;
    if(typeof item === "string") item = items[item];
    return resolveAddress(item, items);
}

export const fetchAndStorePropertyPolicies = debounce(async function(property) {
    const json = await Promise.all([
        fetch(`${api.settings.apiBase}/properties?viewpoint=${new Date().toISOString()}&property=${property}`),
        fetch(`${api.settings.apiBase}/permits/policies/issue/statistics?scope=${property}&viewpoint=${new Date().toISOString()}&valid=${format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss")}/&public=true&actual=false&all=true`),
    ])
    .then(values => Promise.all(values.map(res => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then(values => merge({}, ...values));
    each(json, (value, key) => {
        //console.log(key, value);
        if(has(value, "items")) json.items[key] = value.items;
    });
    updateItems(json);
}, 1000, {
    trailing:true,
    leading:true,
});

export async function fetchAndStorePropertyGeojson(property) {
    const res = await fetch(`https://api.parkingboss.com/v1/properties/${property.id || property}/map?viewpoint=${new Date().toISOString()}`)
    //const res = await fetch(`https://customapps.parkingboss.com/geojson/master/${property.uuid.replace("-","")}.json`);
    const json = await res.json();
    updateItems({
        items:{
            "geojson":json,
        },
    });
    return json;
}

export async function fetchPropertyGeojson(property) {
    const res = await fetch(`https://api.parkingboss.com/v1/properties/${property.id || property}/map?viewpoint=${new Date().toISOString()}`)
    //const res = await fetch(`https://customapps.parkingboss.com/geojson/master/${property.uuid.replace("-","")}.json`);
    const json = await res.json();
    return json;
}
